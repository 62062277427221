import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import FlashLib from 'flashlib_onlyplay';
import animationTypes from '../animations/animationTypes';
import animationCreator from 'Engine/animations/animationCreator';

export default class SymbolAnimation extends FlashLib.MovieClip {
  constructor(data, displayItemData) {
    super(data, displayItemData);

    this.offsets = {
      'symbol_1_min_spine': { x: 0, y: 0 },
      'symbol_2_min_spine': { x: 0, y: 0 },
      'symbol_3_min_spine': { x: 0, y: 0 },
    };
    this.animationNames = {
      1: 'wild',
      2: 'bell',
      3: 'watermelon',
      4: 'grape',
      5: 'orange',
      6: 'lemon',
      7: 'plum',
      8: 'cherry',
      9: 'jocker_idle',
    };

    this.stopAnimationNames = {
      9: 'jocker_stop',
    }
    // this.id = id;
    // this.type = type !== '' ? '_' + type : type;
    // this.symbolPosition = position;

    this.animation = null;
    this.onComplete = () => {};
    this.onStopAnimationComplete = () => {};
    this.animationStopListener = {
      complete: () => {
        this.animation.state.removeListener(this.animationStopListener)
        this.animation.state.addListener(this.animationListener);
        this.onStopAnimationComplete(this)
      }
    };
    this.animationListener = {
      complete: () => {
        this.animation.state.removeListener(this.animationListener)
        this.onComplete(this)
      }
    };
    this.init()
    this.addListeners();
  }

  init() {
    this.animation = animationCreator.createAnimation(animationTypes.SYMBOLS);
    this.animation.visible = false;
    this.addChild(this.animation);
  }

  addListeners() {
    GlobalDispatcher.add('stateStart:jackpotGameInState', ()=> this.isActive = this.animation.visible, this);
    GlobalDispatcher.add('stateStart:jackpotGameOutState', () => this.animation.visible = this.isActive, this);
  }

  start(loop) {
    // let frame = FlashLib.createItemFromLibrary('symbolsFrames', 'GameFlashLib');
    // frame.id = this.id;
    // this.addChild(frame);

    this._playSpineAnimation(loop);

      // GlobalDispatcher.dispatch('symbolAnimation:start', this.symbolPosition);

  }

  _playSpineAnimation(loop) {
    this.animation.visible = true;
    this.animation.state.setAnimation(0, this.animationNames[this.id], loop);
  }
  startStopAnimation() {
    if (!this.stopAnimationNames[this.id]) return;
    this.animation.visible = true;
    this.animation.state.removeListener(this.animationListener)
    this.animation.state.addListener(this.animationStopListener);
    GlobalDispatcher.dispatch('animationSymbol: showStopAnimation', this.parent); //transfer symbol
    this.animation.skeleton.setToSetupPose();
    this.animation.state.setAnimation(0, this.stopAnimationNames[this.id], false);
    this.animation.state.update(0.25);
  }
s
  _complete() {
    // this.animation.visible = false;
    this.onComplete();
    // this.emit('animationComplete', this);
  }

  stop() {
    if (this.animation) {
      this.animation.visible = false;
    }
  }
}
