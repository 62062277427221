import FlashLib from 'flashlib_onlyplay';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import GameModel from './model/GameModel';
import { gsap } from 'gsap';
import BaseSlotWinningsController from 'Engine/base/winings/BaseSlotWinningsController';
import './style.css';

export default class Main extends FlashLib.MovieClip {
  constructor(data, displayItemData) {
    super(data, displayItemData);

    this.reelsContainer = null;
    this.uiContainer = null;

    this.addListeners();
  }

  onGameResized(data) {
    if (data.isLandscape) {
      this.slotContainer.anchorDefault()
      this.slotContainer.scale.set(this.slotContainer.displayData.scaleX);
      this.logo.visible = false;
    } else if (data.isMobile && data.isPortrait) {
      this.slotContainer.pivot.x = this.slotContainer.displayData.width / 2;
      this.slotContainer.x = this.backgroundContainer.displayData.width / 2 - 5;
      this.slotContainer.y = this.slotContainer.displayData.y + 10;
      this.slotContainer.scale.set(0.92);
      this.logo.visible = true;
    }
  }

  init() {
    this.winningsController = new BaseSlotWinningsController({nextWinTimeout: 2000});

    this.slotContainer = this.getChildByName('SlotWrapper');
    this.slot = this.slotContainer.getChildByName('slotContainer');
    this.reelsContainer = this.slot.getChildByName('reelsContainer');

    this.jackpotGameContainer = this.jackpotGame.getChildByName('jackpotGame');
    this.popupWonJackpotGame = this.jackpotGame.getChildByName('popupWonJackpotGame');
    this.freespinsStart = this.getChildByName('freespinsStart');
    this.freespinsEnd = this.getChildByName('freespinsEnd');

    // this.linesContainer = this.slotContainer.getChildByName('LinesContainer');

    // this.nearWinContainer = this.slot_container.getChildByName('NearWinContainer');
    // this.lineWinningContainer = this.slot_container.getChildByName('LineWinningContainer');

    this.backgroundContainer = this.getChildByName('backgroundContainer');
    this.logo = this.getChildByName('logo');
    // this.bigWinContainer = this.getChildByName('BigWinContainer');
    // this.purchaseContainer = this.getChildByName('PurchaseContainer');
    //this.linesContainer.showLines(GameModel.lines);
  }

  addListeners() {
    GlobalDispatcher.add('model:linesChanged', this.onLinesChanged, this);
    GlobalDispatcher.add('stateManager.stateChanged', this.onStateChanged, this);
    GlobalDispatcher.add('stateEnd:initState', this.onInitStateEnd, this);
    window.OPWrapperService.eventManager.add(
          window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
          this.onGameResized,
          this
        );
    GlobalDispatcher.add('antisipator: start', this.onStartAntisipator, this);
    GlobalDispatcher.add('antisipator: stop', this.onStopAntisipator, this);

    // GlobalDispatcher.add('model:getServerData', this.checkForFreeSpins, this);
  }

  onStartAntisipator({params: reel}) {
    reel.displayObjectUpdateTransform();
    const reelLocalPosition = this.toLocal({ x: 0 , y: 0 }, reel);
    this.offsetX = reelLocalPosition.x + (reel.displayData.width/2 - 100) ;
    this.offsetY = reelLocalPosition.y + (reel.displayData.height/2 -50) ;
    this.pivot.x = this.offsetX;
    this.pivot.y = this.offsetY;
    this.x += this.offsetX;
    this.y += this.offsetY;

    setTimeout(() => {
      gsap.to(this.scale, {x: 1.03, y: 1.03, duration:2.5,  onComplete: () => {
        }})
    }, 10)
  }

  onStopAntisipator() {
    setTimeout(() => {
      gsap.killTweensOf(this.scale);
      gsap.to(this.scale,  {x: 1, y:1, duration:0.2, onComplete: () => {
        this.pivot.x = 0;
        this.pivot.y = 0;
        this.x -= this.offsetX;
        this.y -= this.offsetY;
      }})
    }, 10)
  }

  onLinesChanged(data) {
    //this.linesContainer.showLines(data.params);
  }

  onInitStateEnd() {
    // if (!EntryPoint.GameModel.isFreeSpinsMode) return;
    // this.backgroundContainer.showFreeSpinsBack();
    // SoundManager.pause(sounds.BACKGROUND.name);
    // SoundManager.play(sounds.FREE_SPINS_BACK.name, sounds.FREE_SPINS_BACK.volume, true, 'music');
  }

  onStateChanged(data) {
    switch (data.params) {
      case 'initState':
        break;
      case 'waitUserInteractionState':
        break;
      case 'idleState':
        break;
      case 'showWonJackpotGameState':
        this.popupWonJackpotGame.show();
        break;
      case 'jackpotGameInState':
        this.popupWonJackpotGame.hide();
        this.jackpotGameContainer.show();
        this.winningsController.reset();
        break;
      case 'showWonJackpotState':
        break;
      case 'jackpotGameOutState':
        this.jackpotGameContainer.hide();
        this.winningsController.start();
        break;
      case 'freespinsGameInState':
        this.freespinsStart.show();
        break;
      case 'freespinsGameOutState':
        this.freespinsEnd.show();
        break;
      case 'requestRollingState':
        this.reelsContainer.hideWinningSymbols();
        this.reelsContainer.start();
        this.winningsController.reset();
        // this.linesContainer.hideLines();
        // this.linesContainer.reset();
        // this.symbolsAnimationsContainer.removeAnimations();
        this.reelsContainer.setActive(true);
        // this.lineWinningContainer.hide();
        break;
      case 'stopRollingState':
        this.reelsContainer.stop(GameModel.reels);
        break;
      case 'showWinState':
        break;
      case 'showLinesWinState':
        break;
      case 'showWinningsState':
        this.winningsController.start();
        this.reelsContainer.setActive(false);
        break;
      case 'expandSymbolsState':
        this.reelsContainer.hideWinningSymbols();
        this.winningsController.reset();
        this.reelsContainer.setActive(false);
        break;
    }
  }
}
