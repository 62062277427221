import { goldFontStyle } from '../../fontStyles';
import BasePopup from '../popupWin/BasePopup';
import { gsap } from 'gsap';
import EntryPoint from 'Engine/EntryPoint';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import BaseMoneyFormat from 'Engine/base/moneyFormat/MoneyFormat';
import SoundManager from 'Engine/soundManager/SoundManager';
import eSoundType from '../../sounds/eSoundType';

export default class ControllerFreespinsEnd extends BasePopup {
  constructor(data, displayData) {
    super(data, displayData);
    this.alpha = 0;
    this.init()
  }
  init() {
    super.init();
    this.totalWinText = this.getChildByName('freespins_end_total_win')
    this.textFieldTotalWin = this.getChildByName('amount')
    this.setTextStile()
  }
  setTextStile() {
    this.totalWinText.style = { ...this.totalWinText.style, ...goldFontStyle }
    this.textFieldTotalWin.style = { ...this.textFieldTotalWin.style, ...goldFontStyle }
  }

  onShow() {
    super.onShow();
    this.totalWin = 0;
    const arrForDecimals = BaseMoneyFormat.formatCurrency(EntryPoint.GameModel.freespinsTotalWin, EntryPoint.GameModel.currencyInfo.decimals).split('.');
    this.decimalsForAnimation = arrForDecimals[1] ? arrForDecimals[1].length : 0;
    gsap.to(this,  { totalWin: EntryPoint.GameModel.freespinsTotalWin, duration: 2, onComplete: () => {
      setTimeout(() => {
          this.hide();
          this.textFieldTotalWin.text = BaseMoneyFormat.format(EntryPoint.GameModel.freespinsTotalWin);
        }
      ,2000)
      }})
    SoundManager.play(eSoundType.EST_FREESPINS_WIN, 0.5, false, 'winning');
  }
  onHide() {
    super.onHide();
    GlobalDispatcher.dispatch('freespinsEnd: onComplete')
  }

  set totalWin(value) {
    this._totalWin = value
    this.textFieldTotalWin.text = `${value.toFixed(this.decimalsForAnimation)} ${EntryPoint.GameModel.currencyInfo.currency}`;
  }
  get totalWin() {
    return this._totalWin;
  }
}
